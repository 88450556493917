.container {
  width: 100%;
  background-color: #232426;
  padding: 10rem;
  padding-bottom: 2rem;
  color: #fff;
}

.wrapper {
  display: flex;
  gap: 20%;
  // align-items: center;
}

.left {
  flex-basis: 45%;
}
.right {
  display: flex;
  flex-basis: 45%;
  gap: 10rem;

  ul {
    list-style: none;
  }
}

.imgCont {
  width: 30%;
  margin-bottom: 1rem;
}

.imgWrapper {
  width: 100%;
}

.companySummary {
  font-family: 'ones';
  font-style: normal;
  font-weight: 400;
  font-size: 1.9rem;
  line-height: 3.4rem;
  color: #ffffff;
  margin-bottom: 1.5rem;
}

.supportBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.8px solid #ffffff;
  border-radius: 4px;
  font-family: 'ones';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
  background: transparent;
  padding: 2.7rem 12rem;
  gap: 1rem;
}

.aboutUs {
  h3 {
    font-family: 'ones';
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 31px;
    color: #ffffff;
    margin-bottom: 3.3rem;
  }

  ul li {
    font-family: 'ones';
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: #ffffff;
    margin-bottom: 2rem;
    cursor: pointer;
  }
}

.footerBottom {
  padding-top: 3rem;
  border-top: 1px solid #fff;
  margin-top: 15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerBRight {
  display: flex;
  gap: 2rem;
}
.footerBLeft {
  display: flex;
  gap: 8rem;
}
.footerBLeft a,
.footerBRight a {
  color: #fff;
  text-decoration: none;
  font-family: 'ones';
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.6rem;
}
