.formContainer {
  margin-bottom: 3rem;
}
.formInline {
  display: flex;
  gap: 1.8rem;
  width: 100%;
}
.formfull {
  width: 100%;
}

.submitBtn {
  background: #7c2ecb;
  border-radius: 4px;
  font-family: 'ones';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #ffffff;
  outline: none;
  width: 100%;
  text-align: center;
  border: none;
  padding: 1.7rem;
  cursor: pointer;
}
