.wrapper {
  color: #282828;
  h3 {
    font-family: 'merriweather';
    font-style: normal;
    font-weight: 600;
    font-size: 3rem;
    line-height: 5rem;
    text-align: center;
    letter-spacing: -0.01em;
    margin-bottom: 4rem;
    color: #282828;
  }
}

.customeHeader {
  color: red !important;
}
