.saleContainer {
  width: 55%;
  margin: 11rem auto;
  text-align: center;
  font-size: 16px;
}

.saleContainerTitle {
  font-family: 'merriweather';
  font-style: normal;
  font-weight: 600;
  font-size: 4rem;
  line-height: 150%;
  text-align: center;
  color: #282828;
  margin-bottom: 2rem;
}

.saleContainerPara {
  font-family: 'ones';
  font-style: normal;
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 3.4rem;
  text-align: center;
  color: #282828;
}
