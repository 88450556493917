// ones font

@font-face {
  font-family: 'ones';
  font-display: swap;
  font-weight: 300;
  src: url('../assets/fonts/OnestThin.woff') format('woff');
}

@font-face {
  font-family: 'ones';
  font-display: swap;
  font-weight: 400;
  src: url('../assets/fonts/OnestRegular.woff') format('woff');
}

@font-face {
  font-family: '';
  font-display: swap;
  font-weight: 500;
  src: url('../assets/fonts/OnestMedium.woff') format('woff');
}

@font-face {
  font-family: 'ones';
  font-display: swap;
  font-weight: 700;
  src: url('../assets/fonts/OnestBold.woff') format('woff');
}

@font-face {
  font-family: 'ones';
  font-display: swap;
  font-weight: 800;
  src: url('../assets/fonts/OnestBlack.woff') format('woff');
}

// merriwa

@font-face {
  font-family: 'merriweather';
  font-display: swap;
  font-weight: 300;
  src: url('../assets/fonts/merriweather.light.woff') format('woff');
}

@font-face {
  font-family: 'merriweather';
  font-display: swap;
  font-weight: 400;
  src: url('../assets/fonts/merriweather.regular.woff') format('woff');
}

@font-face {
  font-family: 'merriweather';
  font-display: swap;
  font-weight: 600;
  src: url('../assets/fonts/merriweather.bold.woff') format('woff');
}

@font-face {
  font-family: 'merriweather';
  font-display: swap;
  font-weight: 700;
  src: url('../assets/fonts/merriweather.ultrabold.woff') format('woff');
}
