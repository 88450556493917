.header {
  width: 100vw;
  position: relative;
  background-color: #d5c1ec;
  background-size: cover;
  display: flex;
  position: relative;
  font-size: 1.6rem;
  max-height: 99.2rem;
  padding-top: 4/3;
  // aspect-ratio: 4/3;

  font-family: 'ones';
}

.navHeader {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}

.headerBannerr {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.hero {
  display: flex;
  padding-top: 16rem;
  padding-left: 16rem;
  padding-right: 16rem;
  padding-bottom: 12rem;
  gap: 2rem;
  font-family: 'ones';
  position: relative;
}
.leftAside {
  flex-basis: 47%;
  // margin-top: 16.8rem;

  header {
    font-family: 'merriweather';
    font-style: normal;
    font-weight: 600;
    font-size: 6.4rem;
    line-height: 7.8rem;
    color: #1a1e22;
    margin-bottom: 1.6rem;
    max-width: 59.4rem;
  }

  p {
    font-family: 'ones';
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 3.2rem;
    letter-spacing: 0.02em;
    color: #1a1e22;
    margin-bottom: 3.2rem;
    max-width: 48.6rem;
  }

  .tryCta {
    width: 20.9rem;
    height: 5.4rem;
    background: #1a1e22;
    border: 1px solid #1a1e22;
    border-radius: 0.8rem;
    color: #fff;
    font-family: 'ones';
    font-weight: 500;
    line-height: 120%;
    font-size: 1.8rem;
    margin-bottom: 4.8rem;
  }
  .iconSection {
    display: flex;
    gap: 1.2rem;

    .container {
      display: flex;
      font-family: 'ones';
      flex-direction: column;
      align-items: center;
      justify-content: center;
      //   text-align: center;
      background: rgba(31, 26, 34, 0.05);
      backdrop-filter: blur(1.8rem);
      min-width: 11rem;
      width: 11rem;
      height: 11rem;
      border-radius: 9px;
      gap: 2rem;

      &:first-child {
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
      }
    }
    .iconTitle {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.5rem;
      text-align: center;
      font-family: 'ones';
      color: #1a1e22;
      align-self: center;
      max-width: 5.7rem;
    }
  }
}

.rightAside {
  position: absolute;
  right: 12%;
  top: 22%;
}

.imgContainer {
  width: 62.2rem;
  height: 75.6rem;
  // width: 62.2rem;
  // height: 75.6rem;
  // position: relative;
}
