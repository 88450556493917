.serviceItem {
  display: flex;
  flex-direction: column;
  padding: 4rem;
  background: #ffffff;
  border-radius: 6px;
  width: 100%;
  height: 100%;
}
.iconBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f8f4fc;

  margin-bottom: 2rem;
}
.title {
  font-family: 'ones';
  font-style: normal;
  font-weight: 500;
  font-size: 2.35rem;
  line-height: 30px;
  letter-spacing: -0.01em;
  margin-bottom: 1rem;
}

.description {
  font-family: 'ones';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.8rem;
  letter-spacing: -0.01em;
  color: #282828;
}
