.selectField {
  border: none;
  background: none;
  outline: none;
  padding: 1.3rem;
  font-size: 1.4rem;
  color: #282828;
  opacity: 0.8;
  border: 1px solid #ced0d5;
  border-radius: 5px;
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: ones;
  width: 100%;
  &:focus {
    background: none !important;
    outline: none;
  }

  &::placeholder {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    font-family: ones;
    letter-spacing: -0.01em;
  }
}
