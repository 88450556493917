.container {
  padding-left: 16rem;
  padding-right: 16rem;
  padding-top: 10rem;
  padding-bottom: 10rem;
  background: #f5f8fa;
}

.wrapper {
  display: flex;
  gap: 16rem;
  align-items: center;
}

.leftHeader {
  font-family: 'merriweather';
  font-style: normal;
  font-weight: 600;
  font-size: 3rem;
  line-height: 4.8rem;
  color: #282828;
  width: 90%;
  margin-bottom: 1rem;
}
.left {
  flex-basis: 45%;
}

.description {
  font-family: 'ones';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #282828;
  margin-bottom: 1rem;
}

.right {
  flex-basis: 55%;
}
.imgCont {
  width: 100%;
}
.customBtn {
  padding: 16px 42px;
  background: #f5f8fa;
  border: 1px solid rgba(40, 40, 40, 0.8);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'ones';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #282828;
  gap: 1rem;
  cursor: pointer;
}

// .text-gray-400 {
//   --tw-text-opacity: 1;
//   color: rgb(156 163 175 / var(--tw-text-opacity));
// }
