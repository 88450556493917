.container {
  display: flex;
  align-items: center;
  border: 1px solid #ced0d5;
  border-radius: 5px;
  font-family: ones;
  width: 100%;
  textarea {
    border: none;
    background: none;
    outline: none;
    padding: 1.3rem;
    font-size: 1.4rem;
    flex-grow: 1;
    &:focus {
      background: none !important;
      outline: none;
    }

    &::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      font-family: ones;
      letter-spacing: -0.01em;
      color: #282828;
      opacity: 0.4;
    }
  }
}
