.container {
  background-color: #fff;
}

.containerBox {
  margin-left: 15rem;
  margin-top: 9.6rem;
  width: 80%;
  padding-bottom: 7rem;
}

.wrapper {
  align-items: center;
  background-image: url('../../../assets/images/about-back.png');
  background-repeat: no-repeat;
  background-position: 0;
  background-size: contain;
}
.left {
  flex-basis: 70%;
  margin-bottom: 1.5rem;

  h3,
  h4 {
    font-family: 'ones';
    font-style: normal;
    font-weight: 600;
    font-size: 3.5rem;
    line-height: 51px;
    letter-spacing: -0.01em;
    color: #282828;
  }
  h4 {
    font-size: 2.5rem;
  }
  p {
    font-family: 'ones';
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 4rem;
    color: #282828;
    //  opacity: 0.9;
  }
}

.eliOrder {
  margin-left: 5rem;
}

.eliOrder li {
  font-family: 'ones';
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 4rem;
  color: #282828;
}
