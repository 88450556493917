.container {
  background-color: #fff;
}

.containerBox {
  margin-left: 15rem;
  margin-top: 9.6rem;
  width: 80%;
  padding-bottom: 7rem;
}
.wrapper {
  display: flex;
  gap: 13rem;
}

.left {
  flex-basis: 40%;
}
.right {
  flex-basis: 55%;
}

.leftHeader {
  font-family: 'merriweather';
  font-style: normal;
  font-weight: 600;
  font-size: 4rem;
  line-height: 6rem;
  letter-spacing: -0.01em;
  color: #282828;
  margin-bottom: 1rem;
}
.leftDesc {
  font-family: 'ones';
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #282828;

  a {
    color: #7c2ecb;
    text-decoration: none;
    cursor: pointer;
  }
}
