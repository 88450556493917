.site-collapse-custom-panel > .ant-collapse-header {
  background-color: #fff !important;
}
.site-collapse-custom-collapse-faq
  > .site-collapse-custom-panel
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  color: #7c2ecb !important;
}

.site-collapse-custom-collapse {
  background-color: transparent;
}

.site-collapse-custom-collapse .site-collapse-custom-panel {
  background-color: transparent !important;
  border-left: #7c2ecb !important;
  color: #000 !important;
  font-family: 'ones';
}

.site-collapse-custom-collapse .site-collapse-custom-collapse .ant-collapse-content-box {
  border-left: 1px solid #7c2ecb;
}

.site-collapse-custom-panel-faq .ant-collapse-content-box {
  border-left: 3px solid #7c2ecb !important;
  margin-top: 1rem !important;
}
