.container {
  background-color: #fff;
}

.containerBox {
  margin-left: 15rem;
  margin-top: 9.6rem;
  width: 80%;
  padding-bottom: 7rem;
}

.wrapper {
  display: flex;
  gap: 10rem;
  align-items: center;
  background-image: url('../../../assets/images/about-back.png');
  background-repeat: no-repeat;
  background-position: 0;
  background-size: contain;
}
.left {
  flex-basis: 48%;

  h3 {
    font-family: 'ones';
    font-style: normal;
    font-weight: 600;
    font-size: 3.5rem;
    line-height: 51px;
    letter-spacing: -0.01em;
    color: #282828;
  }
  p {
    font-family: 'ones';
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 4rem;
    color: #282828;
    //  opacity: 0.9;
  }
}
.right {
  flex-basis: 55%;
  position: relative;
}
.aboutImg {
  z-index: 99;
  position: relative;
}
.aboutIconWrap {
  position: absolute;
  top: 15%;
  right: -22%;
}
.serviceContainer {
  margin: 0 auto;
  background-color: #f5f8fa;
}
.serviceWrapper {
  padding: 5rem;
  padding-top: 10rem;
  padding-bottom: 6rem;
}
.serviceHeader {
  text-align: center;
  margin-bottom: 5rem;
}
.serviceTitle {
  font-family: 'ones';
  font-style: normal;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 51px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #282828;
}
.servicePara {
  font-family: 'ones';
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 3rem;
  text-align: center;
  color: #282828;
  opacity: 0.9;
  width: 60%;
  text-align: center;
  margin: 0 auto;
}

.serviceList {
  display: flex;
  flex-wrap: wrap;
}
.serviceItemList {
  flex-basis: 33.3%;
  padding: 10px;
  min-height: 34rem;
}
