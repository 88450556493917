.container {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #31476114 0% 0% no-repeat padding-box;
}

.linkCs {
  color: #000 !important;
}

.container_wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  @media (max-width: 920px) {
    padding: 1.5rem 2rem 4rem;
    width: 100%;
  }
}

.content_container {
  background: #31476114 0% 0% no-repeat padding-box;
  border-radius: 1rem;
  height: auto;
  margin-bottom: 5.6rem;
  margin-top: 2.5rem;
  opacity: 0.63;
  padding: 5.6rem 7.5rem;
  width: 102rem;
  @media (max-width: 860px) {
    padding: 1.5rem 2rem 4rem;
    width: 100%;
  }
}

.content_title {
  align-items: center;
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  display: flex;
  font-size: 1.25rem;
  font-weight: 600;
  justify-content: space-between;
  letter-spacing: 1px;
  margin-bottom: 1.5rem;
  min-height: 7rem;
  padding-left: 2.9rem;
  padding-right: 2rem;
  width: 100%;
}

.content_title_icon {
  background: #f30b12 0% 0% no-repeat padding-box;
  display: inline-block;
  height: 3px;
  width: 1.3rem;
}

.content_desc {
  align-items: center;
  border-left: 5px solid #f30d12;
  display: flex;
  margin-bottom: 1.5rem;
  min-height: 4rem;
  padding-left: 2.9rem;
  padding-right: 5rem;
}

.signBtnCs {
  border: 1px solid #000 !important;

  a {
    color: #000 !important;
  }
}

.eliOrder {
  margin-left: 5rem;
}

.eliOrder li {
  font-family: 'ones';
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 4rem;
  color: #282828;
}

.faqHeader {
  font-size: 3rem;
  font-family: 'ones';
  color: #000 !important;
  text-align: center;
  margin-bottom: 2rem;
}
