.ant-menu-light {
  background-color: #242526 !important;
  color: #ffffff80;
  font-family: 'ones' !important;
  font-weight: 500;
  padding-left: 1rem;
  padding-right: 1rem;
}
.ant-menu-item {
  text-transform: capitalize;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
}

.ant-menu.ant-menu-light .ant-menu-item-selected {
  background-color: #3a3a3a !important;
  /* color:#282828 !important; */
  color: #fff !important;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 120%;
  //   padding-top: 2.2rem;
  //   padding-bottom: 2.3rem;
}

// .ant-menu-item:hover {
//   background-color: #3a3a3a !important;
//   color: #fff !important;
//   padding-top: 2.2rem;
//   padding-bottom: 2.3rem;
// }

.ant-menu-light .ant-menu-item:not(.ant-menu-item-disabled):focus-visible {
  outline: none !important;
}

/* .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):active {
    background-color: red;
} */

.ant-menu-dark.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #807b7b !important;
}

.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background-color: #fff !important;
}
.ant-menu-item,
.ant-menu-submenu {
  color: var(--color-text-light-grey) !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 120%;
  margin-bottom: 8px !important;
}

.ant-menu-submenu-title,
.ant-menu-root > .ant-menu-item {
  height: 4.4rem !important;
}

.custom-menu-theme .ant-menu-submenu-title,
.ant-menu-root > .ant-menu-item {
  height: 4rem !important;
}

.ant-menu-title-content {
  width: 100% !important;
}

.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: var(--color-text-light-grey) !important;
}

.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):active {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.ant-menu-title-content {
  margin-left: 1.789rem !important;
}

// rechart
.rechart-cs {
  font-family: ones !important;
  font-size: 1.1rem;
  color: #bcbcbc;
  letter-spacing: 0.01em;
}

.ant-tabs-nav {
  font-family: 'ones' !important;

  border-bottom: 1.5px solid rgba(40, 40, 40, 0.2) !important;
}

.ant-tabs-ink-bar {
  background: #7c2ecb !important;
  font-family: 'Inter' !important;
}
.ant-tabs-tab-btn {
  color: #7c2ecb !important;
  font-family: 'Inter';
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #7c2ecb !important;
  font-weight: 500;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #7c2ecb !important;
  border-color: #7c2ecb !important;
}

.avatar-uploader .ant-upload-drag {
  background-color: #fff !important;
  border: 2px dashed #ced0d5 !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #7c2ecb !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #fff;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #7c2ecb;
}

.ant-radio-checked .ant-radio-inner {
  background-color: #7c2ecb !important;
  border-color: #7c2ecb !important;
}

.avatar-uploader.template-img .ant-upload-drag {
  background: #e6e8eb !important;
  height: 30rem;
}
#components-collapse-demo-collapsible .ant-space {
  width: 100%;
}
