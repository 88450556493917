.container {
  // position: absolute;
  //  top: -50px;
  //  left: 100px;
  width: 100%;
}
.smsP {
  width: 100%;
  height: 100%;
  // height: 100%;
  // left: 0;
  // top: 0;
  // width: 100%;
  // position: absolute;
  object-fit: cover;
}
