.formWrapper {
  width: 100%;
  margin-bottom: 2rem;
}

.formInput {
  margin-top: 0.5rem;
}
.errorDisplay {
  font-size: 1.4rem;
  color: red;
  font-family: ones;
  font-weight: 400;
}

.formBox {
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1rem;
  color: #282828;
}
