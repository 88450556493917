.container {
  background-color: #f5f8fa;
  font-size: 1.6rem;
}

.containerBox {
  margin-top: 9.6rem;
  width: 70%;
  margin-inline: auto;
}

.header {
  margin-bottom: 3.2rem;
  h3 {
    font-family: 'merriweather';
    font-style: normal;
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #282828;
    margin-bottom: 1rem;
  }

  p {
    font-family: 'ones';
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.5rem;
    letter-spacing: -0.01em;
    color: #282828;
    opacity: 0.9;
  }
}

.tableContainer {
  background: #ffffff;
  box-shadow: 0px 2px 15px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 4.9rem;
  margin-bottom: 5rem;
}

.tableContainerHeader {
  h4 {
    font-family: 'ones';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.3rem;
    color: #282828;
    margin-bottom: 1rem;
  }

  p {
    font-family: 'ones';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #282828;
    opacity: 0.8;
    margin-bottom: 1rem;
  }
}
.tableContainerWrap:first-child {
  margin-bottom: 3rem;
}

.sectionPrice {
  margin-bottom: 5rem;
}

.priceSection {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  gap: 1.6rem;
}

// .leftAside {
//   width: 40%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;

//   .asideTitle {
//     margin-bottom: 1.5rem;
//     font-size: 2.3rem;
//   }
//   .asideSubTitle {
//     font-size: 1.7rem;
//   }
//   .asideContent {
//     margin-bottom: 2rem;
//     font-size: 1.8rem;
//     letter-spacing: -0.01em;
//     color: #282828;
//     opacity: 0.9;
//   }
// }
.rightAside {
  width: 33.3%;
  flex-grow: 1;
  flex-shrink: 1;

  .rightAsideContainer {
    padding: 3rem;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    background-color: #fff;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05);
  }
  .priceProps {
    flex: 1;
  }

  .buttonWrap {
    margin-bottom: 1.5rem;

    h4 {
      font-size: 3rem;

      sup {
        font-size: 1.4rem;
        opacity: 0.8;
      }
    }
  }

  .priceList {
    list-style-type: none;
  }

  .priceTypeBtn {
    width: 100%;
    cursor: pointer;
    color: #fff;
    border: none;
    outline: none;
    width: 20rem;
    border-radius: 5px;
    background-color: #4372c4;
    padding: 1.1rem 2.4rem;
  }
  .priceTypeTitle {
    font-size: 1.9rem;
  }
  .priceListItem {
    display: flex;
    gap: 1.5rem;
    // align-items: center;
    margin-bottom: 1.5rem;
    color: #282828;
    opacity: 0.9;

    p {
      flex-grow: 1;
      margin: 0;
      line-height: 1.9rem;
      font-size: 1.6rem;
    }
  }

  .buttonWrapFooter {
    text-align: center;
    margin-block: 1.5rem;
  }
  .priceTypeBtnVariant {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #7c2ecb;
    color: #fff;
    border-radius: 5px;
    padding: 1.1rem 2.4rem;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .priceTypeBtnInActive {
    background-color: #fff;
    border: 1px solid#7c2ecb;
    color: #7c2ecb;
  }
}
