.customeHeader .ant-collapse-header {
  font-family: 'ones';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.03em;
  color: #282828;
}

.ant-collapse-item.customeHeader {
  border-bottom: 1px solid rgba(107, 113, 123, 0.3) !important;
}
