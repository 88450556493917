.container {
  display: flex;
  align-items: center;
  font-family: 'ones';
  font-size: 1.6rem;
  padding-inline: 7rem;
  text-transform: capitalize;
  height: 10rem;
}

.headerLeft {
  flex-basis: 10%;
  margin-right: auto;
}

.headerRight {
  display: flex;
  align-items: center;
  gap: 3.2rem;
  margin-right: 3rem;
}
.authCont {
  display: flex;
  align-items: center;
  gap: 2rem;
}

ul.headerRight,
ul.authCont {
  list-style: none;
}

ul.authCont li a {
  text-decoration: none;
}

ul.authCont li:last-child {
  display: none;
}

ul.headerRight li a {
  text-decoration: none;
  color: #1a1e22;
  cursor: pointer;
  position: relative;
  z-index: 9999;
  font-size: 1.7rem;
  font-family: 500;
}

ul.headerRight a:active,
ul.headerRight a:visited,
ul.headerRight a:link {
  color: #1a1e22;
  position: relative;
}

ul.headerRight li:not(.demolink, .signin):hover {
  border-bottom: 2px solid #7c2ecb;
}

.signin {
  border-radius: 5px;
  padding: 1.1rem 2.4rem;
  background-color: #7c2ecb;

  a {
    color: #fff !important;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 120%;
  }
}

.demolink {
  font-family: 'ones';
  border: 2px solid #1a1e22;
  padding: 1rem 2rem;
  border-radius: 5px;
  a {
    color: #1a1e22;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 120%;
    letter-spacing: -0.01em;
  }
}
